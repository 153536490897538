<template>
  <q-input
    v-bind="$attrs"
    :value="value"
    :label="label"
    :hint="hint"
    :type="isViewPassword ? 'text' : 'password'"
    :rules="rules"
    outlined
    ref="input"
    @input="$emit('input', $event)"
  >
    <template v-slot:append>
      <q-btn
        fab-mini
        flat
        :icon="isViewPassword ? 'mdi-eye-off' : 'mdi-eye'"
        @click.stop="$emit('update:isViewPassword', !isViewPassword)"
      />
    </template>
  </q-input>
</template>

<script>
import withFormControlValidation from '@/shared/mixins/withFormControlValidation';

export default {
  mixins: [withFormControlValidation('input')],
  props: {
    value: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => 'Password',
    },
    hint: {
      type: String,
      default: () => undefined,
    },
    isViewPassword: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
