<template>
  <q-input
    v-bind="$attrs"
    :label="label"
    :dense="dense"
    :value="value"
    :rules="combinedRules"
    ref="input"
    outlined
    @input="$emit('input', $event.trim())"
  />
</template>

<script>
import withFormControlValidation from '@/shared/mixins/withFormControlValidation';
import { validations } from '@/shared/utils';

export default {
  mixins: [withFormControlValidation('input')],
  name: 'DEmailInput',
  props: {
    value: {
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Email',
    },
  },
  computed: {
    combinedRules: ({ rules }) => [
      ...rules,
      (val) => (val ? validations.validateEmail(String(val)) : true),
    ],
  },
};
</script>
