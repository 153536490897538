<template>
  <d-edit-card
    ref="form"
    :key="$route.params.id"
    :id="$route.params.id"
    :fetch-function="fetchUser"
    :entity.sync="form"
    prev-page="UsersList"
    :card-title="`User ${isEditingForm ? 'updating' : 'creation'}`"
    remove-confirm-title="Are you sure you want to delete this user"
    remove-success-title="User successfully removed"
    :remove-function="usersController.removeUser"
    :submit-function="submitForm"
    redirect-after-creation="UsersEdit"
    :submit-success-title="`User successfully ${
      isEditingForm ? 'updated' : 'created'
    }`"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div
            class="col-xs-12 col-md-6 q-col-gutter-md"
            :class="$q.screen.lt.md ? 'q-mb-md' : 'q-pr-md'"
          >
            <q-input
              v-model.trim="data.username"
              outlined
              label="Username"
              aria-label="none"
              hint="Required field*"
              :dense="$q.screen.lt.md"
              :rules="[rules.notNullField, rules.login]"
            />
            <d-email-input
              v-model="data.userEmail"
              :dense="$q.screen.lt.md"
              label="Email"
            />
            <d-password-input
              v-if="isPasswordSelectShowed"
              v-model.trim="data.password"
              label="Password"
              aria-label="none"
              :dense="$q.screen.lt.md"
              :hint="!isEditingForm ? 'Required field*' : 'Change password'"
              :is-view-password.sync="isViewPassword"
              :rules="
                !isEditingForm
                  ? [rules.notNullField, rules.password]
                  : [rules.password]
              "
            />
            <d-password-input
              v-if="isPasswordSelectShowed"
              v-model.trim="data.confirmPassword"
              label="Confirm password"
              :dense="$q.screen.lt.md"
              :hint="`The entered passwords must match. ${
                !isEditingForm && 'Required field*'
              }`"
              :is-view-password.sync="isViewConfirmPassword"
              :rules="
                !isEditingForm
                  ? [rules.notNullField, rules.isPasswordConfirmed]
                  : [rules.isPasswordConfirmed]
              "
            />
            <q-select
              outlined
              :value="data.roles"
              :options="rolesDictionary"
              :dense="$q.screen.lt.md"
              label="Roles"
              multiple
              hint="Required field*"
              clearable
              :rules="[rules.notNullField]"
              @input="setRole"
            />
            <q-select
              outlined
              v-model="data.locale"
              :options="localeDictionary"
              label="Locale"
              hint="Required field*"
              :rules="[rules.notNullField]"
              :dense="$q.screen.lt.md"
            />
            <div class="row">
              <div class="col-lg-6 col-xs-12 q-mb-md" v-if="isSelectedAgent || isSelectedOperator">
                <q-tooltip v-if="data.allowCreateRequisites">
                  The change is forbidden, because the checkbox "Create requisites" is activated
                </q-tooltip>
                <q-toggle
                    v-model="data.allowEditRequisitePayload"
                    :dense="$q.screen.lt.md"
                    checked-icon="mdi-check"
                    color="green"
                    size="xl"
                    label="Edit payload"
                    unchecked-icon="mdi-close"
                    :disable="data.allowCreateRequisites"
                />
              </div>
              <q-toggle
                v-model="data.allowCustomerTransactionId"
                :dense="$q.screen.lt.md"
                class="col-lg-6 col-xs-12 q-mb-md"
                checked-icon="mdi-check"
                color="green"
                size="xl"
                label="Edit Customer Transaction ID"
                unchecked-icon="mdi-close"
              />
              <q-toggle
                v-if="isAllowMerchantSecretUid"
                v-model="data.allowMerchantSecretUid"
                :dense="$q.screen.lt.md"
                class="col-lg-6 col-xs-12 q-mb-md"
                checked-icon="mdi-check"
                color="green"
                size="xl"
                label="Display merchant uid and secret key"
                unchecked-icon="mdi-close"
              />
              <q-toggle
                class="col-lg-6 col-xs-12"
                v-model="data.active"
                :dense="$q.screen.lt.md"
                checked-icon="mdi-check"
                color="green"
                size="xl"
                label="Account status"
                unchecked-icon="mdi-close"
              />
              <q-toggle
                v-if="isSelectedAgent"
                v-model="data.countPendingRefill"
                :dense="$q.screen.lt.md"
                class="col-lg-6 col-xs-12 q-mb-md"
                checked-icon="mdi-check"
                color="green"
                size="xl"
                label="Count pending refill"
                unchecked-icon="mdi-close"
              />
              <q-toggle
                v-if="isSelectedAgent || isSelectedOperator"
                v-model="data.allowCreateRequisites"
                :dense="$q.screen.lt.md"
                class="col-lg-6 col-xs-12 q-mb-md"
                checked-icon="mdi-check"
                color="green"
                size="xl"
                label="Create payment requisite"
                unchecked-icon="mdi-close"
              />
              <div v-if="isSelectedAgent || isSelectedOperator" class="col-lg-6 col-xs-12 q-mb-md">
                <q-tooltip v-if="data.allowCreateRequisites">
                  The change is forbidden, because the checkbox "Create requisites" is activated
                </q-tooltip>
                <q-toggle
                  v-model="data.allowEditRequisiteRefillMethod"
                  :dense="$q.screen.lt.md"
                  checked-icon="mdi-check"
                  color="green"
                  size="xl"
                  label="Edit refill method"
                  unchecked-icon="mdi-close"
                  :disable="data.allowCreateRequisites"
                />
              </div>
              <div v-if="isSelectedAgent || isSelectedOperator" class="col-lg-6 col-xs-12 q-mb-md">
                <q-tooltip v-if="data.allowCreateRequisites">
                  The change is forbidden, because the checkbox "Create requisites" is activated
                </q-tooltip>
                <q-toggle
                  v-model="data.allowEditRequisiteWeight"
                  :dense="$q.screen.lt.md"
                  checked-icon="mdi-check"
                  color="green"
                  size="xl"
                  label="Edit weight"
                  unchecked-icon="mdi-close"
                  :disable="data.allowCreateRequisites"
                />
              </div>
              <div v-if="isSelectedAgent || isSelectedOperator" class="col-lg-6 col-xs-12 q-mb-md">
                <q-tooltip v-if="data.allowCreateRequisites">
                  The change is forbidden, because the checkbox "Create requisites" is activated
                </q-tooltip>
                <q-toggle
                  v-model="data.allowSwitchRequisite"
                  :dense="$q.screen.lt.md"
                  checked-icon="mdi-check"
                  color="green"
                  size="xl"
                  label="On/off requisite"
                  unchecked-icon="mdi-close"
                  :disable="data.allowCreateRequisites"
                />
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 q-col-gutter-md">
            <d-user-select
              v-if="isSelectedOperator"
              :dense="$q.screen.lt.md"
              v-model="data.agent"
              :options="agents"
              label="Agent"
              class="col-12"
              :multiple="false"
            />
            <d-email-input
              v-if="isSelectedAgent"
              v-model="data.agentEmail"
              :dense="$q.screen.lt.md"
              label="Agent email"
            />
            <q-select
              v-if="isSelectedAgent || isSelectedSupportOfficer"
              emit-value
              map-options
              :dense="$q.screen.lt.md"
              :options="currencyDictionary"
              v-model="data.currencyId"
              :multiple="isSelectedSupportOfficer"
              outlined
              label="Currency"
              class="col-3"
              :rules="[(val) => !!val || 'Please select value']"
              hint="Required field*"
              option-value="id"
              option-label="name"
            />
            <d-allowed-ips-table
              :items="data.allowedIps"
              @on-add="addIpHandler($event, data)"
              @on-remove="removeIpHandler($event, data)"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading, remove }">
      <d-edit-form-actions
        :is-editing-form="isEditingForm"
        prev-page="UsersList"
        @remove="remove"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DAllowedIpsTable } from '@/features/allowed-ips-table';
import { DEditFormActions } from '@/features/edit-form-actions';
import { DEmailInput } from '@/features/email-input';
import { DPasswordInput } from '@/features/password-input';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import {
  usersController as apiController,
  currencyController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import { authUtils, notify } from '@/shared/utils';
import { mapGetters } from 'vuex';

export default {
  components: {
    DEditCard,
    DEditFormActions,
    DPasswordInput,
    DAllowedIpsTable,
    DUserSelect,
    DEmailInput,
  },
  mixins: [withIsEditingForm()],
  data: (vm) => ({
    usersController: apiController(authUtils.getRoleByHierarchy()),
    form: {
      userEmail: '',
      agentEmail: '',
      username: '',
      password: '',
      roles: [],
      locale: '',
      active: true,
      confirmPassword: '',
      allowedIps: [],
      agent: null,
      allowCustomerTransactionId: false,
      allowMerchantSecretUid: false,
      countPendingRefill: false,
      currencyId: null,
      // requisite data
      allowCreateRequisites: false,
      allowSwitchRequisite: false,
      allowEditRequisiteWeight: false,
      allowEditRequisitePayload: false,
      allowEditRequisiteRefillMethod: false,
    },
    agents: [],
    isViewPassword: false,
    isViewConfirmPassword: false,
    localeDictionary: ['en'],
    currencyDictionary: [],
    rules: {
      login: (val) => /^[a-zA-Z\d-_]{1,}$/.test(val) || 'Wrong value',
      password: (val) =>
        val.length ? /^[a-zA-Z\d-_]{8,}$/.test(val) || 'Wrong value' : true,
      notNullField: (val) => (val && val.length > 0) || 'Please type value',
      isPasswordConfirmed: (val) => {
        if (!vm.form.password) return true;
        return vm.form.password === val || 'Password mismatch';
      },
    },
  }),

  async mounted() {
    try {
      this.$refs.form.setLoading(true);
      const { data } = await this.usersController.getUsers();
      this.agents = data.filter((user) =>
        user.roles.includes(ROLES.ROLE_AGENT)
      );
    } catch (e) {
      notify.error(e.message);
    } finally {
      this.$refs.form.setLoading(false);
    }
  },

  watch: {
    isSelectedAgent(val) {
      if (val && !this.currencyDictionary.length) {
        this.fetchCurrency();
      }
    },
    isSelectedSupportOfficer(val) {
      if (val && !this.currencyDictionary.length) {
        this.fetchCurrency();
      }
    },

    'form.allowCreateRequisites'(newValue) {
      if (newValue) {
        this.form.allowSwitchRequisite = true;
        this.form.allowEditRequisiteRefillMethod = true;
        this.form.allowEditRequisitePayload = true;
        this.form.allowEditRequisiteWeight = true;
      }
    },
  },

  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),
    isAllowMerchantSecretUid: ({ $store }) =>
      !!$store.getters.GET_USER_PERMISSIONS.allowMerchantSecretUid,
    isPasswordSelectShowed: ({ isSupervisor, isEditingForm }) =>
      isSupervisor ? !isEditingForm : true,
    isSelectedSupportOfficer: ({ form }) =>
      authUtils.checkRoles([ROLES.ROLE_SUPPORT_OFFICER], form.roles),
    isSelectedAgent: ({ form }) =>
      authUtils.checkRoles([ROLES.ROLE_AGENT], form.roles),
    isSelectedOperator: ({ form }) => {
      return authUtils.checkRoles([ROLES.ROLE_OPERATOR], form?.roles);
    },
    isSupervisor: () => authUtils.checkRoles([ROLES.ROLE_SUPERVISOR]),
    rolesDictionary: ({ isSupervisor }) =>
      isSupervisor
        ? Object.values(ROLES).filter(
            (role) => role === ROLES.ROLE_AGENT || role === ROLES.ROLE_OPERATOR
          )
        : Object.values(ROLES),
  },

  methods: {
    async submitForm({
      // eslint-disable-next-line no-unused-vars
      confirmPassword,
      allowedIps,
      agent,
      agentEmail,
      currencyId,
      countPendingRefill,
      allowCreateRequisites,
      allowSwitchRequisite,
      allowEditRequisiteRefillMethod,
      allowEditRequisitePayload,
      allowEditRequisiteWeight,
      ...payload
    }) {
      const action = this.isEditingForm
        ? this.usersController.updateUser
        : this.usersController.createUser;

      return await action({
        ...payload,
        ...(!this.isSupervisor && {
          allowedIps,
        }),
        ...(this.isSelectedOperator && {
          agentId: agent.id,
        }),
        ...(this.isSelectedAgent && {
          agentEmail,
          agentCurrencyId: currencyId,
        }),
        ...(this.isSelectedSupportOfficer && {
          supportCurrenciesId: currencyId,
        }),
        countPendingRefill: this.isSelectedAgent ? countPendingRefill : false,
        ...((this.isSelectedAgent || this.isSelectedOperator) && {
          allowCreateRequisites,
          allowSwitchRequisite,
          allowEditRequisiteRefillMethod,
          allowEditRequisitePayload,
          allowEditRequisiteWeight,
        }),
      });
    },

    async fetchUser(id) {
      const {
        data: {
          supportCurrencies,
          agentCurrency,
          countPendingRefill,
          permissions,
          ...payload
        },
      } = await this.usersController.getUserById(id);

      const {
        allowCreateRequisites,
        allowSwitchRequisite,
        allowEditRequisiteWeight,
        allowEditRequisitePayload,
        allowEditRequisiteRefillMethod,
        allowCustomerTransactionId,
        allowMerchantSecretUid,
      } = permissions;

      return {
        data: {
          ...payload,
          ...(payload.roles.includes(ROLES.ROLE_SUPPORT_OFFICER) && {
            currencyId: supportCurrencies?.map((el) => el.id),
          }),
          ...(payload.roles.includes(ROLES.ROLE_AGENT) && {
            currencyId: agentCurrency?.id,
          }),
          countPendingRefill: countPendingRefill || false,

          allowCreateRequisites: allowCreateRequisites || false,
          allowSwitchRequisite: allowSwitchRequisite || false,
          allowEditRequisiteWeight: allowEditRequisiteWeight || false,
          allowEditRequisitePayload: allowEditRequisitePayload || false,
          allowEditRequisiteRefillMethod: allowEditRequisiteRefillMethod || false,

          allowCustomerTransactionId: allowCustomerTransactionId || false,
          allowMerchantSecretUid: allowMerchantSecretUid || false,
        },
      };
    },

    async fetchCurrency() {
      try {
        this.$refs.form.setLoading(false);
        const { data } = await currencyController(
          authUtils.getRoleByHierarchy()
        ).getCurrency();
        this.currencyDictionary = data;
      } catch (e) {
        notify.error('Dictionary loading error');
      } finally {
        this.$refs.form.setLoading(false);
      }
    },

    addIpHandler(value, data) {
      data.allowedIps.push(value);
    },

    removeIpHandler(idx, data) {
      data.allowedIps.splice(idx, 1);
    },

    setRole(value) {
      this.form.currencyId = null;
      this.form.roles = value;
    },
  },
};
</script>
