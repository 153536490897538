<template>
  <q-select
    v-bind="$attrs"
    :multiple="multiple"
    :use-input="useInput"
    :value="value"
    :options="localOptions"
    :rules="rules"
    :label="label"
    :hint="hint"
    option-value="id"
    option-label="username"
    :outlined="outlined"
    @input="$emit('input', $event)"
    @filter="filterFn"
    ref="select"
    :dense="dense"
  >
    <template v-if="value" #append>
      <q-icon
        name="mdi-close-circle"
        @click.stop="$emit('input', clearValue)"
        class="cursor-pointer"
      />
    </template>
    <template #option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-item-label>
            {{ scope.opt.username }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <template v-for="role in scope.opt.roles">
            <q-chip dense color="blue-5" class="text-white" :key="role">
              {{ role }}
            </q-chip>
          </template>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import withFormControlValidation from '@/shared/mixins/withFormControlValidation';

export default {
  mixins: [withFormControlValidation('select')],
  name: 'DUserSelect',
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Users',
    },
    hint: {
      type: String,
      default: () => '',
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    useInput: {
      type: Boolean,
      default: () => true,
    },
    clearValue: {
      type: [Array, Object, Number, String],
      default: () => null,
    },
  },
  data: (vm) => ({
    localOptions: vm.options,
  }),

  methods: {
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.localOptions = this.options.filter(
          (v) => v.username.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
};
</script>
