<template>
  <q-table
    row-key="ip"
    dense
    title="Allowed IPs"
    flat
    :columns="columns"
    :data="allowedIps"
  >
    <template v-slot:top>
      <div class="col-12">
        <q-input placeholder="Enter IP" dense color="primary" v-model="ip">
          <template v-slot:after>
            <q-btn
              :disable="!ip"
              round
              dense
              flat
              icon="mdi-plus-circle"
              @click="onAdd"
            />
          </template>
        </q-input>
      </div>
    </template>
    <template v-slot:body-cell-action-btns="props">
      <q-td :props="props">
        <q-btn size="sm" flat icon="mdi-delete" @click="onRemove(props)" />
      </q-td>
    </template>
  </q-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ip: '',
    columns: [
      {
        name: 'ip',
        label: 'IP address',
        field: 'ip',
        sortable: true,
        align: 'left',
      },
      {
        name: 'action-btns',
        field: 'actionBtns',
        label: 'Actions',
      },
    ],
  }),
  computed: {
    allowedIps: ({ items }) => items.map((ip) => ({ ip })),
  },
  methods: {
    onRemove({ rowIndex }) {
      this.$emit('on-remove', rowIndex);
    },
    onAdd() {
      this.$emit('on-add', this.ip);
      this.ip = '';
    },
  },
};
</script>
